import axios from 'axios'
import router from "@/router";
import { Message } from 'element-ui'

switch (process.env.NODE_ENV) {
  case 'development':
    console.log('开发环境')
    break
  case 'production':
    console.log('生产环境')
    break
  default:
    console.log('测试环境')
    break
}

axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8' //配置请求头
//请求拦截器
axios.interceptors.request.use(
	(config) => {
		const token = window.localStorage.getItem('Tit-token')
		token && (config.headers['tit-token'] = token);
		// token && (config.headers.Authorization = token);
		return config
	},
	(err) => {
		Message.error('请求异常')
		console.log('请求大错误：', err)
	}
)
let status =true;
//响应拦截器
axios.interceptors.response.use(
	(res) => {
		// debugger
		if (res.data.code === 0) {
			// oss接口-正确回调
			return res.data ;
		} else if(res.data.code === 200){
			// 业务接口-正确回调
			return res.data.data;
		}else if (res.data.code === 401 && status) {
			Message.error('token失效，请重新登录！')
			status = false;
			router.replace({
				path: '/login'
			});
		}else{
			console.log(res);
			res.data.msg ? Message.error(res.data.msg) :void 0;
			return Promise.reject(res);
		}
	},
	(err) => {
		// debugger
		console.log(err.response.status)
		// 用户权限失效，重新获取token逻辑
		if (err.response.status === 401) {
			// console.log(err.response.data);
			err.response.data.loginUri?
				window.location.href = err.response.data.loginUri
			: void 0;
			return false;
		}
		Message.error('响应异常')
		console.log('响应大错误：', err.response);
		return Promise.reject(res);
	}
)

// 封装aixos调用方法
function request({type = 'get',args}) {
    return new Promise((resolve,rejuect) => {
        axios[type](...args)
		.then((data) => {
            // >>必须这样写，不能改成!data
            if (data !== false) {
                resolve(data)
            }
        })
		.catch(err => {
			console.log(err);
		})
    })
}

function get(...args) {
    return request({ type: 'get', args })
}
function post(...args) {
    return request({ type: 'post', args })
}
function put(...args) {
    return request({ type: 'put', args })
}
function del(...args) {
    return request({ type: 'delete', args })
}

// 所有接口
axios.API = {
	// =============================token权限==========================
	// code码获取token
	getCode:(data) => post('/auth/codeCallback', data),
	// 获取个人信息以及菜单
	// getUserInfo:() => post('/auth/userinfo'),
	getUserInfo:() => get('/auth/user/getUserInfo'),
	// 登出
	// logout:(url) => get('/auth/logout?redirect_uri='+url),
	logout:(url) => get('/auth/sso/logout'),
	// =============================业务接口===========================
	//部门机构树
	orgTree:(data) => post('/auth/organ/getOrganTree',data),
	//部门机构新增
	orgSave:(data) => post('/auth/organ',data),
	//部门机构信息更新
	orgUpdate:(data) => put('/auth/organ',data),
	//部门机构删除
	orgDel:(id) => del('/auth/organ/'+id),
	//用户分页获取
	userPage:(current,size,data) => post('/auth/user/page?current='+current+'&size='+size,data),
	//用户新增
	userSave:(data) => post('/auth/user',data),
	//用户信息更新
	userUpdate:(data) => put('/auth/user',data),
	//用户删除
	userDel:(id) => del('/auth/user/'+id),
	//微信小程序用户分页获取
	appletUserPage:(current,size,data) => post('/auth/appletAccount/page?current='+current+'&size='+size,data),
	//微信小程序用户信息更新
	appletUserUpdate:(data) => put('/auth/appletAccount',data),
	//微信小程序用户删除
	appletUserDel:(id) => del('/auth/appletAccount/'+id),
	//角色全部列表
	roleList:(data) => post('/auth/role/list',data),
	//角色信息分页获取
	rolePage:(current,size,data) => post('/auth/role/page?current='+current+'&size='+size,data),
	//角色信息新增
	roleSave:(data) => post('/auth/role',data),
	//角色信息信息更新
	roleMenu:(id,data) => post('/auth/roleMenu/saveRoleMenu/'+id,data),
	//角色信息信息更新
	roleProject:(id,data) => post('/auth/roleProject/saveRoleProject/'+id,data),
	//角色菜单权限分配
	roleUpdate:(data) => put('/auth/role',data),
	//角色信息删除
	roleDel:(id) => del('/auth/role/'+id),
	//项目全部列表
	projectList:(data) => post('/auth/project/list',data),
	//项目分页获取
	projectPage:(current,size,data) => post('/auth/project/page?current='+current+'&size='+size,data),
	//项目信息新增
	projectSave:(data) => post('/auth/project',data),
	//项目修改
	projectUpdate:(data) => put('/auth/project',data),
	//项目信息删除
	projectDel:(id) => del('/auth/project/'+id),
	//项目产品信息更新
	projectProduct:(id,data) => post('/auth/projectProduct/saveProjectProduct/'+id,data),
	//产品全部列表
	productList:(data) => post('/auth/product/list',data),
	//产品分页获取
	productPage:(current,size,data) => post('/auth/product/page?current='+current+'&size='+size,data),
	//产品信息新增
	productSave:(data) => post('/auth/product',data),
	//产品修改
	productUpdate:(data) => put('/auth/product',data),
	//产品信息删除
	productDel:(id) => del('/auth/product/'+id),
	//菜单信息分页获取
	// menuPage:(current,size,data) => post('/auth/menu/page?current='+current+'&size='+size,data),
	//菜单树获取
	menuTree:(data) => post('/auth/menu/getMenuTree/'+data),
	//通过用户或许菜单
	menuTreeByUser:() => post("/auth/menu/getMenuTreeByUser"),
	//菜单信息新增
	menuSave:(data) => post('/auth/menu',data),
	//菜单信息信息更新
	menuUpdate:(data) => put('/auth/menu',data),
	//菜单信息删除
	menuDel:(id) => del('/auth/menu/'+id),
	//日志分页查询
	logPage:(current,size,data) => post('/auth/log/page?current='+current+'&size='+size,data),
	//修改密码
	resetPwd:(data) => post('/auth/user/resetPassword',data),
	//重置用户密码
	adminReset:(data) => post('/auth/user/adminResetPassword',data),
	//是否登录
	isLogin:(data) => get('/auth/login/getLoginInfo',data),
	//认证平台登录
	doSsoLogin:(data)=> post('/auth/sso/login?back='+ encodeURIComponent(location.href),data),
	//通过ticket登录
	doLoginByTicket:(data)=>post('/auth/login/doLoginByTicket',data),
	//获取sever端地址
	goSsoAuthUrl:(data)=>post('/auth/login/getSsoAuthUrl',data),
	//微信用户列表
	wechatUserPage:(current,size,data) => post('/auth/baseThirdAccount/page?current='+current+'&size='+size,data),
	// 获取动态路由和菜单
	getRouterAndMenu:(data) => post('/auth/menu/getRoute',data),
	//路段全部列表
	roadList:(data) => post('/auth/sysRoad/list',data),
	//路段分页获取
	roadPage:(current,size,data) => post('/auth/sysRoad/page?current='+current+'&size='+size,data),
	//路段信息新增
	roadSave:(data) => post('/auth/sysRoad',data),
	//路段修改
	roadUpdate:(data) => put('/auth/sysRoad',data),
	//路段信息删除
	roadDel:(id) => del('/auth/sysRoad/'+id),
	//信息联系人全部列表
	smsList:(data) => post('/auth/smsUser/list',data),
	//信息联系人分页获取
	smsPage:(current,size,data) => post('/auth/smsUser/page?current='+current+'&size='+size,data),
	//信息联系人新增
	smsSave:(data) => post('/auth/smsUser',data),
	//信息联系人修改
	smsUpdate:(data) => put('/auth/smsUser',data),
	//信息联系人删除
	smsDel:(id) => del('/auth/smsUser/'+id),
}
export default axios.API
